// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = <T extends Function>(cb: T, wait = 20) => {
  let h: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
};

export const transliterate = (word: string): string => {
  let answer = "";
  const transliterationMap: { [key: string]: string } = {
    Ё: "YO",
    Й: "I",
    Ц: "TS",
    У: "U",
    К: "K",
    Е: "E",
    Н: "N",
    Г: "G",
    Ш: "SH",
    Щ: "SCH",
    З: "Z",
    Х: "H",
    Ъ: "'",
    ё: "yo",
    й: "i",
    ц: "ts",
    у: "u",
    к: "k",
    е: "e",
    н: "n",
    г: "g",
    ш: "sh",
    щ: "sch",
    з: "z",
    х: "h",
    ъ: "'",
    Ф: "F",
    Ы: "I",
    В: "V",
    А: "A",
    П: "P",
    Р: "R",
    О: "O",
    Л: "L",
    Д: "D",
    Ж: "ZH",
    Э: "E",
    ф: "f",
    ы: "i",
    в: "v",
    а: "a",
    п: "p",
    р: "r",
    о: "o",
    л: "l",
    д: "d",
    ж: "zh",
    э: "e",
    Я: "Ya",
    Ч: "CH",
    С: "S",
    М: "M",
    И: "I",
    Т: "T",
    Ь: "'",
    Б: "B",
    Ю: "YU",
    я: "ya",
    ч: "ch",
    с: "s",
    м: "m",
    и: "i",
    т: "t",
    ь: "'",
    б: "b",
    ю: "yu",
  };

  for (const char of word) {
    answer += transliterationMap[char] || char;
  }

  return answer;
};

export const removeTypeNameFromGQLResult = (result: Record<string, any>) => {
  return JSON.parse(
    JSON.stringify(result, (key, value) => {
      if (key === "__typename") return;
      return value;
    }),
  );
};

export const restrictNumbers = (t: string) =>
  t.replace(new RegExp(/[^\d]/, "ig"), "");

// Функция для перевода из ISO 8601 в HH:mm
export const isoToTime = (isoDuration: string) => {
  // Регулярное выражение для извлечения часов и минут
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
  const matches = isoDuration.match(regex);

  // Получаем часы и минуты, если они указаны, если нет, то 0
  const hours = matches[1] ? parseInt(matches[1], 10) : 0;
  const minutes = matches[2] ? parseInt(matches[2], 10) : 0;

  // Приводим в формат HH:mm
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

// Функция для перевода из HH:mm в ISO 8601
export const timeToIso = (time: string) => {
  // Разделяем часы и минуты
  const [hours, minutes] = time.split(":").map(Number);

  // Формируем строку ISO 8601
  let isoDuration = "PT";
  if (hours > 0) isoDuration += `${hours}H`;
  if (minutes > 0) isoDuration += `${minutes}M`;

  return isoDuration;
};
